import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SignupForm from '../components/signupform'

const Roasters = ({ pageContext, data }) => {
  const { roaster } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} Snoffeecob review${
    totalCount === 1 ? '' : 's'
  } with roaster ${roaster}`
  return (
    <Layout>
      <SEO
        title="Snoffeecob Reviews by bean Roasters"
        description="Snoffeecob Blogs by Roasters"
      />
      <div className="blog-tags">
        <h1>{tagHeader}</h1>
        <ul className="tag-list">
          {edges.map(({ node }) => {
            const { title, date } = node.frontmatter
            const { slug } = node.fields
            return (
              <li key={slug}>
                <Link to={slug}>{title}</Link>
                <small>
                  <span> | {date}</span>
                </small>
              </li>
            )
          })}
        </ul>
        <span>
          <Link to="/tags">← All tags</Link>
        </span>
      </div>
      <div className="signup-box">
        <SignupForm />
      </div>
    </Layout>
  )
}

export default Roasters

export const pageQuery = graphql`
  query($roaster: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { roaster: { eq: $roaster } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
